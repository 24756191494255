import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ButtonType } from '@components/atoms/buttons/components/button/enums/buttons.enum';
import { GenericObject } from '@utils/models/Types';
import { ConfirmServiceService } from '@components/organisms/confirm-dialog/services/confirm-service.service';
import { ConfirmDialogProps } from '@components/organisms/confirm-dialog/confirm-dialog.enum';
import { NavigationService } from '@services/navigation.service';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';
import { TaskBoardSection } from '@pages/task-board/enums/task-board.enum';
import { TaskBoardService } from '@pages/task-board/services/task-board.service';
import { MeService } from 'app/core/services/me.service';
import { MeSession } from 'app/core/types/me.type';
import { ToastMsgService } from '@services/toast-msg.service';

@Component({
  selector: 'review-modified-fields',
  templateUrl: './review-modified-fields.component.html',
  styleUrl: './review-modified-fields.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewModifiedFieldsComponent implements OnInit{
  @Input() originalData: GenericObject;
  @Input() updateData: GenericObject;
  @Input() dataMapper: any[];
  @Input() section: TaskBoardSection;
  @Input() idTask: number;

  viewChangesPopupVisible: boolean = false;
  meSession: MeSession;
  creatorTask: string;
  
  protected readonly ButtonType = ButtonType;

  constructor(
    private _confirmationService: ConfirmServiceService,
    private _navigationService: NavigationService,
    private _taskBoardService: TaskBoardService,
    private _meService: MeService,
    private _toastService: ToastMsgService,
  ) {}

  ngOnInit(): void {
    this.meSession = this._meService.getMeSession();
    this.creatorTask = this.originalData['maintenanceTask'].audit.creatorCdsid;
  }

  handleRejectConfirmation() {
    this._confirmationService
      .asyncConfirmation({
        [ConfirmDialogProps.TITLE_KEY]: 'CONFIRMATION.REJECT_TITLE',
        [ConfirmDialogProps.SUMMARY_KEY]: 'CONFIRMATION.REJECT_SUMMARY',
        [ConfirmDialogProps.CONFIRM_LABEL_KEY]: 'CONFIRMATION.REJECT_BUTTON',
        [ConfirmDialogProps.CONFIRM_CALLBACK]: () => this._handleReject(),
        [ConfirmDialogProps.HIDE_DISMISS]: true
      })
      .then();
  }

  handleApproveConfirmation() {
    this._confirmationService
      .asyncConfirmation({
        [ConfirmDialogProps.TITLE_KEY]: 'CONFIRMATION.APPROVE_TITLE',
        [ConfirmDialogProps.SUMMARY_KEY]: 'CONFIRMATION.APPROVE_SUMMARY',
        [ConfirmDialogProps.CONFIRM_LABEL_KEY]: 'CONFIRMATION.APPROVE_BUTTON',
        [ConfirmDialogProps.CONFIRM_CALLBACK]: () => this._handleApprove(),
        [ConfirmDialogProps.HIDE_DISMISS]: true
      })
      .then();
  }

  backToTaskboard() {
    this._navigationService.navigateToRouteConfig(TaskBoardRouteConfig[this.section]).then();
  }

  private _handleApprove() {
    this._taskBoardService.approveTask(this.section, this.originalData['id'], this.idTask).subscribe({
      next: () => {
        this._toastService.success('TASK_BOARD.CONFIRMATION.APPROVED', {
          id: this.originalData['id']
        });
      },
      error: () => {
        this._toastService.error('TASK_BOARD.CONFIRMATION.ERROR_APPROVED');
      }
    })
    this.backToTaskboard();
  }

  private _handleReject() {
    this._taskBoardService.rejectedTask(this.section, this.originalData['id'], this.idTask).subscribe({
      next: () => {
        this._toastService.success('TASK_BOARD.CONFIRMATION.REJECTED', {
          id: this.originalData['id']
        });
      },
      error: () => {
        this._toastService.error('TASK_BOARD.CONFIRMATION.ERROR_REJECTED');
      }
    })
    this.backToTaskboard();
  }
}

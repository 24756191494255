<div class="review-modified-fields">
  <div class="review-modified-fields__view">
    <next-button [labelKey]="'REVIEW_MODIFIED_FIELDS.VIEW_MODIFIED_FIELDS'" [type]="ButtonType.SECONDARY"
                 (clickEvent)="viewChangesPopupVisible = true"></next-button>
  </div>
  <div class="review-modified-fields__action">
    <next-button [labelKey]="'BUTTONS.CANCEL'" [type]="ButtonType.SECONDARY" (clickEvent)="backToTaskboard()"></next-button>
    <next-button [labelKey]="'REVIEW_MODIFIED_FIELDS.REJECT'" (clickEvent)="handleRejectConfirmation()" [type]="ButtonType.CONTRAST"></next-button>
    <next-button [labelKey]="'REVIEW_MODIFIED_FIELDS.APPROVE'" (clickEvent)="handleApproveConfirmation()"
    [isDisabled]="meSession.cdsid === creatorTask"></next-button>
  </div>
</div>

<compare-modified-fields-popup [isOpen]="viewChangesPopupVisible"
                               [originalData]="originalData"
                               [updateData]="updateData"
                               [dataMapper]="dataMapper"
                               (cancel)="viewChangesPopupVisible = false">
</compare-modified-fields-popup>

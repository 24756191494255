<next-dialog [visible]="isOpen" [titleKey]="'REVIEW_MODIFIED_FIELDS.TITLE'" [submitLabelKey]="'BUTTONS.CLOSE'"
             [hideDismiss]="true" [hideCancelButton]="true" (visibleChange)="cancel.emit()" (submitClick)="cancel.emit()">
  <div class="compare-modified-fields" dialogContent>
    <div class="compare-modified-fields__columns">
      <div class="compare-modified-fields__original">
        <div
          class="compare-modified-fields__section-title compare-modified-fields__section-title--initial">{{'REVIEW_MODIFIED_FIELDS.ORIGINAL' | translate}}</div>
      </div>
      <div class="compare-modified-fields__update">
        <div
          class="compare-modified-fields__section-title compare-modified-fields__section-title--initial">{{'REVIEW_MODIFIED_FIELDS.UPDATE' | translate}}</div>
      </div>
    </div>

    <div class="compare-modified-fields__list">
      <ng-container *ngTemplateOutlet="recursiveListTpl; context: {list: data, hierarchyLevel: 0}"></ng-container>
    </div>
  </div>

</next-dialog>

<ng-template #recursiveListTpl let-list="list" let-hierarchyLevel="hierarchyLevel" let-isArray="isArray">
  <div
    [ngClass]="[
      'compare-modified-fields',
      'compare-modified-fields--' + hierarchyLevel,
      !!hierarchyLevel ? 'compare-modified-fields--children' : '',
      isArray ? 'compare-modified-fields--array-children' : ''
    ] | appendText"
    *ngFor="let item of list; index as i">
    <div class="compare-modified-fields__columns">
      <div class="compare-modified-fields__original">
        <div class="compare-modified-fields__section-title">{{item.titleKey | translate}}</div>
        <div class="">{{item.originalData }}</div>
      </div>
      <div class="compare-modified-fields__update">
        <div class="compare-modified-fields__section-title">{{item.titleKey | translate}}</div>
        <div class="">{{item.updateData }}</div>
      </div>
    </div>
    <div class="compare-modified-fields__list" *ngIf="item.children">
      <ng-container
        *ngTemplateOutlet="recursiveListTpl; context: {list: item.children, hierarchyLevel: hierarchyLevel +1, isArray: item.isArray}"></ng-container>
    </div>
  </div>
  <ng-container></ng-container>
</ng-template>
